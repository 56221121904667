"use client";
import { useState } from "react";
import { Bounded } from "@/components/Bounded";
import { InputField, TextareaField } from "@/components/Fields";
import { Icon } from "@iconify/react";
import { PrismicRichText } from "@/components/PrismicRichText";
import Image from "next/image";
import vectorQuarterCircle2 from "@/images/Vector-half-circle.svg";
import vectorRadialCircle from "@/images/Vector-Dots.svg";

const ContactForm = ({ slice, showText = false }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
  });
  const [statusMessage, setStatusMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatusMessage(""); // Clear previous status message

    try {
      const response = await fetch("/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setStatusMessage("Message sent successfully!");
        setIsSuccess(true);
        setFormData({ name: "", email: "", message: "", phone: "" }); // Clear form fields
      } else {
        const errorData = await response.json();
        setStatusMessage(`Error: ${errorData.message}`);
        setIsSuccess(false);
      }
    } catch (error) {
      setStatusMessage("An unexpected error occurred. Please try again later.");
    }
  };

  return (
    <Bounded
      as="section"
      size="widest"
      className="mx-auto relative max-w-7xl my-20 bg-[#F0F9F7] rounded-md"
    >
      <Image
        src={vectorRadialCircle}
        alt=""
        role="presentation"
        width={58}
        height={69}
        className="absolute -top-14 left-0 ml-0 w-14 sm:w-30 md:w-40"
      />
      <Image
        src={vectorQuarterCircle2}
        alt=""
        role="presentation"
        width={172}
        height={273}
        className="absolute bottom-0 right-0 mt-1 w-20 sm:-mt-1 md:-mt-1 lg:-mt-1 sm:w-20 md:w-28"
      />
      <div
        className={`flex gap-20 mx-auto items-start ${showText ? "flex-col md:flex-row" : "flex-col"}`}
      >
        {/* Left Section for Text */}
        {showText && (
          <div className={` ${showText ? "w-full md:w-2/3" : "w-full"}`}>
            <PrismicRichText field={slice.primary.description} />
          </div>
        )}

        {/* Contact Form */}
        <form
          onSubmit={handleSubmit}
          className={`${showText ? "w-full md:w-1/3" : "w-full"} space-y-4`}
        >
          <h3 className="text-primary2 uppercase tracking-wider mb-4 text-base font-bold leading-6 w-full">
            Send us a message
          </h3>
          <InputField
            label="Name"
            name="name"
            placeholder="Jane Doe"
            value={formData.name}
            onChange={handleChange}
          />
          <InputField
            id="email"
            label="Email Address"
            name="email"
            type="email"
            placeholder="jane.doe@example.com"
            value={formData.email}
            onChange={handleChange}
          />
          <InputField
            id="phone"
            label="Phone Number"
            name="phone"
            type="tel"
            placeholder="Enter your phone number"
            required={false}
            value={formData.phone}
            onChange={handleChange}
          />
          <TextareaField
            label="Message"
            name="message"
            placeholder="Write your message here…"
            value={formData.message}
            onChange={handleChange}
          />
          <button
            type="submit"
            className="bg-primary hover:bg-black text-white text-base font-medium h-full py-1 px-6 rounded-full transition duration-300 ease-in-out focus:outline-none focus:ring-2 flex items-center"
          >
            Send message <Icon icon="mdi:envelope" className="w-4 h-6 ml-2" />
          </button>
          {statusMessage && (
            <div
              className={`mt-2 text-sm ${isSuccess ? "text-green-600" : "text-red-600"}`}
            >
              {statusMessage}
            </div>
          )}
        </form>
      </div>
    </Bounded>
  );
};

export default ContactForm;
