import clsx from "clsx";
import { ReactNode } from "react";

const formClasses =
  "block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm";

function Label({ id, children, srOnly }) {
  return (
    <label
      htmlFor={id}
      className={clsx(
        "mb-3 block text-sm font-medium text-gray-700",
        srOnly && "sr-only"
      )}
    >
      {children}
    </label>
  );
}

export function TextField({
  id,
  label,
  labelSrOnly,
  type = "text",
  className = "",
  name = "",
  autoComplete = "",
  required,
  placeholder = "",
  ...props
}) {
  return (
    <div className={className}>
      {label && (
        <Label id={id} srOnly={labelSrOnly}>
          {label}
        </Label>
      )}
      <input
        id={id}
        type={type}
        {...props}
        required
        className={formClasses}
        placeholder={placeholder}
        autoComplete={autoComplete}
        name={name}
      />
    </div>
  );
}

export function SelectField({ id, label, className = "", ...props }) {
  return (
    <div className={className}>
      {label && <Label id={id}>{label}</Label>}
      <select id={id} {...props} className={clsx(formClasses, "pr-8")} />
    </div>
  );
}

export const Field = ({ label, children }) => {
  return (
    <label>
      <span className="text-sm text-slate-500">{label}</span>
      {children}
    </label>
  );
};

export const InputField = ({
  label,
  name,
  type = "text",
  placeholder,
  required = true,
  onChange = () => {},
  ...props
}) => {
  return (
    <Field label={label}>
      <input
        name={name}
        type={type}
        {...props}
        onChange={onChange}
        required={required}
        placeholder={placeholder}
        className="w-full rounded-none border-b border-slate-200 py-3 pl-3 pr-7 my-2 text-slate-800 placeholder-slate-400"
      />
    </Field>
  );
};

export const TextareaField = ({
  label,
  name,
  placeholder,
  required = true,
  onChange = () => {},
  ...props
}) => {
  return (
    <Field label={label}>
      <textarea
        name={name}
        {...props}
        onChange={onChange}
        required={required}
        placeholder={placeholder}
        className="h-40 w-full rounded-none border-b border-slate-200 py-3 pl-3 pr-7 text-slate-800 placeholder-slate-400"
      />
    </Field>
  );
};
