"use client";

import { useEffect, useRef } from "react";
import { PrismicRichText } from "@prismicio/react";
import { Button } from "@/components/Button";
import { Container } from "@/components/Container";
import { PrismicNextImage } from "@prismicio/next";
import Typewriter from "typewriter-effect/dist/core";
import Image from "next/image";
import { PostcodeSearch } from "@/components/PostcodeSearch";

import vectorDots from "@/images/Vector-Dots.svg";
import vectorRadialCircle2 from "@/images/Vector-radial-circles2.svg";

export default function HeroWithImageRight({ slice, withoutCta = false }) {
  const typewriterRef = useRef(null);

  const typewriterEffectWords = slice.primary.typewriter_effect_words
    .filter((item) => item.text !== "")
    .map((item) => item.text);

  useEffect(() => {
    if (typewriterRef.current && typewriterEffectWords.length > 0) {
      const typewriter = new Typewriter(typewriterRef.current, {
        loop: true,
        delay: 75,
        deleteSpeed: 100,
        cursorClassName: "text-primary font-normal",
      });

      typewriterEffectWords.forEach((word) => {
        typewriter.typeString(word).pauseFor(1500).deleteAll();
      });

      typewriter.start();
    }
  }, [typewriterEffectWords]);
  const image = slice.primary.image;

  return (
    <section
      id={slice.primary.anchor || undefined}
      className="relative overflow-hidden py-10 sm:py-20 max-w-6xl mx-auto"
    >
      <div className="flex flex-col md:flex-row w-full justify-between items-center">
        <Image
          src={vectorDots}
          alt=""
          role="presentation"
          width={"auto"}
          height={"auto"}
          className="absolute bottom-0 left-1/2 mt-52 w-40 sm:w-40 md:w-40"
          priority={true}
        />
        <Image
          src={vectorRadialCircle2}
          alt=""
          role="presentation"
          width={"auto"}
          height={"auto"}
          className="absolute top-5 right-0 mt-0 w-30 sm:w-20 md:w-40"
        />
        <div className="flex flex-col w-full md:w-1/2">
          <div className="relative text-left">
            <PrismicRichText
              field={slice.primary.eyebrow}
              components={{
                paragraph: ({ children }) => (
                  <p className="relative text-primary uppercase tracking-wider mb-4 text-base font-bold  w-full ">
                    {children}
                  </p>
                ),
              }}
            />
            <PrismicRichText
              field={slice.primary.title}
              components={{
                heading1: ({ children }) => (
                  <h1 className="w-full font-bold text-4xl md:text-6xl tracking-tight text-slate-900 ">
                    {slice.primary.typewriter_effect_words?.length > 0 && (
                      <div
                        aria-live="polite"
                        className="text-primary"
                        ref={typewriterRef}
                      ></div>
                    )}
                    {children}
                  </h1>
                ),
                heading2: ({ children }) => (
                  <h2 className="relative font-bold text-3xl lg:text-4xl md:text-4xl leading-loose sm:text-4xl  tracking-tight text-slate-900 ">
                    {children}
                  </h2>
                ),
                strong: ({ children }) => {
                  return <span className="relative">{children}</span>;
                },
              }}
            />
            <PrismicRichText
              field={slice.primary.description}
              components={{
                paragraph: ({ children }) => (
                  <p className="relative mt-6 max-w-2xl sm:max-w-md text-base text-gray-700">
                    {children}
                  </p>
                ),
              }}
            />
          </div>

          {slice.primary?.show_postcode_search && (
            <div className="relative py-2 text-left pt:4 lg:pt-4 md:max-w-[400px] mt-10">
              <PostcodeSearch
                id={slice.id}
                align="center"
                buttonLabel={slice.primary?.button_label}
                inputPlaceholder={slice.primary?.input_placeholder}
              />
            </div>
          )}

          <div className="mt-10 flex justify-center gap-x-6">
            {slice.items?.map((item, idx) => {
              return item.cta_type === "Primary" ? (
                <Button key={idx} field={item.cta_link} color="white">
                  {item.cta_label}
                </Button>
              ) : (
                <Button
                  key={idx}
                  field={item.cta_link}
                  variant="solid"
                  className="bg-light-black"
                >
                  <svg
                    aria-hidden="true"
                    className="h-3 w-3 flex-none fill-light-blue group-active:fill-current"
                  >
                    <path d="m9.997 6.91-7.583 3.447A1 1 0 0 1 1 9.447V2.553a1 1 0 0 1 1.414-.91L9.997 5.09c.782.355.782 1.465 0 1.82Z" />
                  </svg>
                  <span className="ml-3 text-white">{item.cta_label}</span>
                </Button>
              );
            })}
          </div>
        </div>
        <div className="flex items-end justify-end relative  w-full md:w-1/2">
          <PrismicNextImage
            field={image}
            sizes="100vw"
            className="w-[492px]"
            alt={image.alt || ""}
            priority={true}
          />
        </div>
      </div>
    </section>
  );
}
