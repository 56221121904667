"use client";

import { useState } from "react";
import { Icon } from "@iconify/react";
import { InputField, TextareaField } from "@/components/Fields";

export default function CleanerJoinForm() {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    postcode: "",
    gender: "",
    hasExperience: "",
    hasCrimeRecord: "",
  });

  const [statusMessage, setStatusMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const setGender = (gender) => {
    setFormData((prevData) => ({
      ...prevData,
      gender: gender,
    }));
  };

  const setHasExperience = (hasExperience) => {
    setFormData((prevData) => ({
      ...prevData,
      hasExperience: hasExperience,
    }));
  };

  const setHasCrimeRecord = (hasCrimeRecord) => {
    setFormData((prevData) => ({
      ...prevData,
      hasCrimeRecord: hasCrimeRecord,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatusMessage(""); // Clear previous status message

    try {
      const response = await fetch("/api/join-as-cleaner", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setStatusMessage("Message sent successfully!");
        setIsSuccess(true);
        setFormData({
          fullName: "",
          email: "",
          phone: "",
          postcode: "",
          gender: "",
          hasExperience: "",
          hasCrimeRecord: "",
        }); // Clear form fields
      } else {
        const errorData = await response.json();
        setStatusMessage(`Error: ${errorData.message}`);
        setIsSuccess(false);
      }
    } catch (error) {
      setStatusMessage("An unexpected error occurred. Please try again later.");
    }
  };

  return (
    <section id="cleaner-join-form" className="mx-auto max-w-7xl my-20">
      <div className="bg-white shadow-md rounded-lg p-8 max-w-3xl mx-auto mt-10">
        <h3 className="text-primary uppercase tracking-wider mb-4 text-base text-center font-bold leading-6 w-full">
          Apply Form
        </h3>
        <h2 className="mx-auto w-full font-bold text-3xl tracking-tight text-slate-700 sm:text-4xl text-center mb-20">
          Fill this form Carefully
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Full Name */}
          <div className="relative">
            <input
              type="text"
              name="fullName"
              placeholder="Enter your full name"
              className="w-full border border-gray-300 rounded-full px-12 py-4 focus:ring-2 focus:ring-primary focus:outline-none placeholder-gray-500 text-gray-700"
              value={formData.fullName}
              onChange={handleChange}
            />
            <span className="absolute top-1/2 transform -translate-y-1/2 left-4 text-gray-400">
              <Icon icon="mdi:account" className="w-6 h-6" />
            </span>
          </div>

          {/* Email */}
          <div className="relative">
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              className="w-full border border-gray-300 rounded-full px-12 py-4 focus:ring-2 focus:ring-primary focus:outline-none placeholder-gray-500 text-gray-700"
              value={formData.email}
              onChange={handleChange}
            />
            <span className="absolute top-1/2 transform -translate-y-1/2 left-4 text-gray-400">
              <Icon icon="mdi:email" className="w-6 h-6" />
            </span>
          </div>

          {/* Phone Number */}
          <div className="relative">
            <input
              type="tel"
              name="phone"
              placeholder="Enter your phone number"
              className="w-full border border-gray-300 rounded-full px-12 py-4 focus:ring-2 focus:ring-primary focus:outline-none placeholder-gray-500 text-gray-700"
              value={formData.phone}
              onChange={handleChange}
            />
            <span className="absolute top-1/2 transform -translate-y-1/2 left-4 text-gray-400">
              <Icon icon="mdi:phone" className="w-6 h-6" />
            </span>
          </div>

          {/* Postcode */}
          <div className="relative">
            <input
              type="text"
              name="postcode"
              placeholder="Enter your postcode"
              className="w-full border border-gray-300 rounded-full px-12 py-4 focus:ring-2 focus:ring-primary focus:outline-none placeholder-gray-500 text-gray-700"
              value={formData.postcode}
              onChange={handleChange}
            />
            <span className="absolute top-1/2 transform -translate-y-1/2 left-4 text-gray-400">
              <Icon icon="mdi:map-marker" className="w-6 h-6" />
            </span>
          </div>

          <hr className="border-gray-200 my-4" />

          {/* Gender */}
          <div className="flex flex-col md:flex-row w-full items-center space-y-2">
            <p className="flex text-base w-full font-medium text-gray-700">
              Are you male or female?
            </p>
            <div className="flex space-x-4 w-full justify-start md:justify-end">
              <button
                type="button"
                className={`px-4 py-2 rounded-lg border ${
                  formData.gender === "Male"
                    ? "bg-primary text-white"
                    : "bg-gray-100 text-gray-700"
                }`}
                onClick={() => setGender("Male")}
              >
                <Icon
                  icon="mdi:gender-male"
                  className="inline-block w-5 h-5 mr-1"
                />
                Male
              </button>
              <button
                type="button"
                className={`px-4 py-2 rounded-lg border ${
                  formData.gender === "Female"
                    ? "bg-primary text-white"
                    : "bg-gray-100 text-gray-700"
                }`}
                onClick={() => setGender("Female")}
              >
                <Icon
                  icon="mdi:gender-female"
                  className="inline-block w-5 h-5 mr-1"
                />
                Female
              </button>
            </div>
          </div>

          {/* Experience */}
          <div className="flex flex-col md:flex-row w-full items-center space-y-2">
            <p className="flex text-base w-full font-medium text-gray-700">
              Do you have professional cleaning experience?
            </p>
            <div className="flex space-x-4 w-full justify-start md:justify-end">
              <button
                type="button"
                className={`px-4 py-2 rounded-lg border ${
                  formData.hasExperience === "Yes"
                    ? "bg-primary text-white"
                    : "bg-gray-100 text-gray-700"
                }`}
                onClick={() => setHasExperience("Yes")}
              >
                Yes
              </button>
              <button
                type="button"
                className={`px-4 py-2 rounded-lg border ${
                  formData.hasExperience === "No"
                    ? "bg-primary text-white"
                    : "bg-gray-100 text-gray-700"
                }`}
                onClick={() => setHasExperience("No")}
              >
                No
              </button>
            </div>
          </div>

          {/* Crime Record */}
          <div className="flex flex-col md:flex-row w-full items-center space-y-2">
            <p className="flex text-base w-full font-medium text-gray-700">
              Have you ever been sentenced for a crime?
            </p>
            <div className="flex space-x-4 w-full justify-start md:justify-end">
              <button
                type="button"
                className={`px-4 py-2 rounded-lg border ${
                  formData.hasCrimeRecord === "Yes"
                    ? "bg-primary text-white"
                    : "bg-gray-100 text-gray-700"
                }`}
                onClick={() => setHasCrimeRecord("Yes")}
              >
                Yes
              </button>
              <button
                type="button"
                className={`px-4 py-2 rounded-lg border ${
                  formData.hasCrimeRecord === "No"
                    ? "bg-primary text-white"
                    : "bg-gray-100 text-gray-700"
                }`}
                onClick={() => setHasCrimeRecord("No")}
              >
                No
              </button>
            </div>
          </div>
          {statusMessage && (
            <div
              className={`mt-2 pt-10 text-sm text-center ${isSuccess ? "text-green-600" : "text-red-600"}`}
            >
              {statusMessage}
            </div>
          )}
          {/* Submit Button */}
          <div className="relative flex items-center justify-center max-w-md mx-auto pt-8">
            <button
              type="submit"
              className="absolute mt-14 w-full bg-primary hover:bg-black text-white text-base font-semibold py-3 rounded-full hover:bg-primary-dark transition duration-300 ease-in-out focus:outline-none"
            >
              Apply Now
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}
